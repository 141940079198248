import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import echartsGL from 'echarts-gl'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en';
import 'lib-flexible/flexible'
import dataV from '@jiaminghi/data-view'

import './assets/css/index.css'

Vue.use(dataV)

Vue.config.productionTip = false
Vue.prototype.$echartsGL = echartsGL
Vue.prototype.$echarts = echarts
Vue.prototype.$ajaxTime = 1000*60
Vue.use(ElementUI, {locale});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
