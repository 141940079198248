<template>
  <div id="app">
      <transition name="bounce"  appear>
        <!-- <keep-alive>  -->
            <router-view/>
        <!-- </keep-alive> -->
      </transition>
  </div>
</template>
<script>
export default {
  data() {
    return {
      clientHeight:0
    }
  },
  watch:{
    'clientHeight':{
      handler (val) {
        this.isFullscreenForNoScroll()
      },
      immediate: true
    }
  },
  mounted() {
      this.clientHeight = document.getElementById('app').clientHeight;
  },
  methods:{
    isFullscreenForNoScroll(){
        var explorer = window.navigator.userAgent.toLowerCase();
        if(explorer.indexOf('chrome')>0){//webkit
            if (document.body.scrollHeight === window.screen.height && document.body.scrollWidth === window.screen.width) {
                document.getElementById('app').style.height = document.documentElement.clientHeight+'px';
                // document.getElementById('app').style.width = document.documentElement.clientWidth/80 +'rem';
            } else {
                document.getElementById('app').style.height = window.screen.height+'px';
                // document.getElementById('app').style.width = window.screen.width/80 +'rem';
                // console.log(document.getElementById('app').style,window.screen.height);
            }
        }else{//IE 9+  fireFox
            if (window.outerHeight === window.screen.height && window.outerWidth === window.screen.width) {
                 document.getElementById('app').style.height = document.documentElement.clientHeight+'px';
                //  document.getElementById('app').style.width = document.documentElement.clientWidth/80+'rem';
            } else {
                document.getElementById('app').style.height = window.screen.height+'px';
                // document.getElementById('app').style.width = window.screen.width/80 +'rem';
            }
        }
    },
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width:100%;
  height: 100vh;
  /* min-height: 13.4375rem; */
  background: url('./assets/images/none_bg.png') no-repeat;
  background-size: 100% 100%;
}
html,body {
  padding: 0;
  margin: 0;
  font-family: "Hiragino Sans GB";
}
.bounce-enter-active {
  animation: bounce-in 1s;
}
/* .bounce-leave-active {
  animation: bounce-in 1s reverse;
} */
@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

</style>
