import { login } from '@/api/login'
import { getToken, setToken } from '@/utils/auth'

const user = {
    state: {
      token: getToken()
    },
  
    mutations: {
      SET_TOKEN: (state, token) => {
        state.token = token
      }
    },
  
    actions: {
      // 登录
      Login({ commit }, userInfo) {
        const usr = userInfo.tel
        const pwd = userInfo.password
        return new Promise((resolve, reject) => {
          login(usr, pwd).then(res => {
            setToken(res.data.token)
            commit('SET_TOKEN', res.data.token)
            resolve()
          }).catch(error => {
            reject(error)
          })
        })
      }
    }
  }
  
  export default user