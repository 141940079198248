import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mapDetail:{},
    foldType:false,
    cpuType:false,
    businessType:false,
    consumerType:false,
    rankType:false,
    userType:false,
    mapDetailType:false,
    totalType:false,
    userCode:''
  },
  mutations: {
    changeMapdetail(state,data) {
      state.mapDetail = data;
    },
    changeFoldshow(state,bl){
      state.foldType = bl;
    },
    changeCpushow(state,bl){
      state.cpuType = bl;
    },
    changeBusinessShow(state,bl) {
      state.businessType = bl;
    },
    changeConsumershow(state,bl) {
      state.consumerType = bl;
    },
    changeRankshow(state,bl){
      state.rankType = bl;
    },
    changeUsershow(state,bl) {
      state.userType = bl;
    },
    changeDetailType(state,bl){
      state.mapDetailType = bl;
    },
    changeTotal(state,bl){
      state.totalType = bl;
    },
    setuserCode(state,code) {
      state.userCode = code;
    }
  },
  actions: {
  },
  modules: {
    user: userModule
  }
})
