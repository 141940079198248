import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
      path: '/',
      name: 'home',
      component: () => import('../views/index.vue')
  },
  {
    path:"/mapdetail",
    name: 'mapdetail',
    component: () => import('../components/map/mapDetail.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login.vue')
  }
]

const router = new VueRouter({
  routes
})

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next();
  } else {
    let token = store.state.user.token;
    if (token === null || token === '' || token === undefined) {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
